import React from "react"
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
import Slide from "react-reveal/Slide"

const PhilosophyAndMission = () => {
  return (
    <section className="grid grid-cols-1 bg-white">
      <div className="py-4 md:py-10">
        <div className="text-xl md:text-2xl lg:text-3xl text-mainColor p-1 my-4 text-center font-bold">
          Our Philosophy and Mission
        </div>
        <hr className="horizontalLine" />
        <div className="container">
          <div className="left">
            <BusinessCenterIcon className="icons" />
          </div>
          <Slide left cascade>
            <div className="right">
              <p className="text-left text-mainColor sm:text-xl tracking-wide ">
                We use our experience in the field to identify gaps and create
                solutions that solve daily challenges and optimize your valuable
                time.
              </p>
            </div>
          </Slide>
        </div>
        <div className="container">
          <div className="left">
            <TrendingUpIcon className="icons" />
          </div>
          <Slide left cascade>
            <div className="right">
              <p className="text-left text-mainColor sm:text-xl tracking-wide">
                We leverage the latest technological advancements to build the
                next generation of products, increasing the quality while
                driving costs down for the customer.
              </p>
            </div>
          </Slide>
        </div>
      </div>
    </section>
  )
}
export default PhilosophyAndMission
