import React from "react"
import EvistampLogo from "../../images/zLogoEvistamp.png"
import BricoBrowserLogo from "../../images/social-media-3.svg"
import ForsintLogo from "../../images/FORSINT-RoundLogo.svg"
import EviquireLogo from "../../images/eviquire-logo.png"
import Pulse from "react-reveal/Pulse"

const Products = () => {
  return (
    <>
      <section className="products hidden md:block" id="products">
        <div className="text-xl md:text-2xl lg:text-3xl text-mainColor p-1 text-center font-bold">
          Our products
        </div>
        <hr className="horizontalLine" />
        <div className="sm:text-xl text-lg text-mainColor text-center">
          Some of our Products developed and under development:
        </div>
        <div className="mainProducts ">
          <Pulse>
            <a href="https://www.bricobrowser.com/" className="firstProduct">
              <h5>BricoBrowser</h5>
              <div className="grid grid-cols-1 lg:grid-cols-2 md:mt-3 md:p-3">
                <div className="m-auto" title="BricoBrowser">
                  <img
                    className="productsLogo"
                    alt="bricoLogo"
                    src={BricoBrowserLogo}
                  />
                </div>
                <div className="text-mainColor tracking-wide text-center md:text-left sm:p-4 md:p-0">
                  The Specialized Browser with superpowers: Multi Screens, Multi
                  Proxies, Multi Workspaces
                </div>
              </div>
            </a>
          </Pulse>
          <Pulse>
            <a href="https://forsint.com/" className="firstProduct">
              <h5>Forsint</h5>
              <div className="grid grid-cols-1 lg:grid-cols-2 md:mt-3 md:p-3">
                <div className="m-auto" title="Forsint">
                  <img
                    className="productsLogo"
                    alt="ForsintLogo"
                    src={ForsintLogo}
                  />
                </div>
                <div className="text-mainColor text-center md:text-left sm:p-4 md:p-0 tracking-wide">
                  The Multi Identity Forensic Web Browser for OSINT &amp;
                  Intelligence Teams. <br />
                </div>
              </div>
            </a>
          </Pulse>
        </div>
        <div className="mainProducts ">
          <Pulse>
            <a href="https://eviquire.com/" className="firstProduct">
              <h5>Eviquire</h5>
              <div className="grid grid-cols-1 lg:grid-cols-2 md:mt-3 md:p-3">
                <div className="m-auto" title="Eviquire">
                  <img
                    className="productsLogo"
                    alt="eviquireLogo"
                    src={EviquireLogo}
                  />
                </div>
                <div className="text-mainColor tracking-wide text-center md:text-left sm:p-4 md:p-0">
                  The Next Generation of Blockchain native Web Forensics,
                  acquisition and timestamping Desktop Application
                </div>
              </div>
            </a>
          </Pulse>
          <Pulse>
            <div className="firstProduct">
              <h5>Evistamp</h5>
              <div className="grid grid-cols-1 lg:grid-cols-2 md:mt-3 md:p-3">
                <div className="m-auto" title="Evistamp">
                  <img
                    className="productsLogo"
                    alt="EvistampLogo"
                    src={EvistampLogo}
                  />
                </div>
                <div className="text-mainColor text-center md:text-left sm:p-4 md:p-0 tracking-wide">
                  The First NextGen Multi Blockchain SaaS and API Timestamp
                  solution
                  <br />
                  <p className="font-bold pt-2">COMING SOON</p>
                </div>
              </div>
            </div>
          </Pulse>
        </div>
      </section>
      <section className="products block md:hidden" id="products">
        <div className="text-xl md:text-2xl lg:text-3xl text-mainColor p-1 text-center font-bold">
          Our products
        </div>
        <hr className="horizontalLine" />
        <div className="sm:text-xl text-lg text-mainColor text-center">
          Some of our Products developed and under development:
        </div>
        <div className="mainProducts ">
          <Pulse>
            <a href="https://www.bricobrowser.com/" className="firstProduct">
              <h5>BricoBrowser</h5>
              <div className="grid grid-cols-1 lg:grid-cols-2 md:mt-3 md:p-3">
                <div className="m-auto" title="BricoBrowser">
                  <img
                    className="productsLogo"
                    alt="bricoLogo"
                    src={BricoBrowserLogo}
                  />
                </div>
                <div className="text-mainColor tracking-wide text-center md:text-left pb-3 md:p-0">
                  The Specialized Browser with superpowers: Multi Screens, Multi
                  Proxies, Multi Workspaces
                </div>
              </div>
            </a>
          </Pulse>
          <Pulse>
            <a href="https://eviquire.com/" className="firstProduct">
              <h5>Eviquire</h5>
              <div className="grid grid-cols-1 lg:grid-cols-2 md:mt-3 md:p-3">
                <div className="m-auto" title="Eviquire">
                  <img
                    className="productsLogo"
                    alt="eviquireLogo"
                    src={EviquireLogo}
                  />
                </div>
                <div className="text-mainColor tracking-wide text-center md:text-left pb-3 md:p-0">
                  The Next Generation of Blockchain native Web Forensics,
                  acquisition and timestamping Desktop Application
                </div>
              </div>
            </a>
          </Pulse>
        </div>
        <div className="mainProducts ">
          <Pulse>
            <a href="https://forsint.com/" className="firstProduct">
              <h5>Forsint</h5>
              <div className="grid grid-cols-1 lg:grid-cols-2 md:mt-3 md:p-3">
                <div className="m-auto" title="Forsint">
                  <img
                    className="productsLogo"
                    alt="ForsintLogo"
                    src={ForsintLogo}
                  />
                </div>
                <div className="text-mainColor text-center md:text-left sm:p-4 md:p-0 tracking-wide">
                  The Multi Identity Forensic Web Browser for OSINT &amp;
                  Intelligence Teams. <br />
                </div>
              </div>
            </a>
          </Pulse>
        </div>
        <div className="mainProducts ">
          <Pulse>
            <div className="firstProduct">
              <h5>Evistamp</h5>
              <div className="grid grid-cols-1 lg:grid-cols-2 md:mt-3 md:p-3">
                <div className="m-auto" title="Evistamp">
                  <img
                    className="productsLogo"
                    alt="EvistampLogo"
                    src={EvistampLogo}
                  />
                </div>
                <div className="text-mainColor text-center md:text-left sm:p-4 md:p-0 tracking-wide">
                  The First NextGen Multi Blockchain SaaS and API Timestamp
                  solution
                  <br />
                  <p className="font-bold pt-2">COMING SOON</p>
                </div>
              </div>
            </div>
          </Pulse>
        </div>
      </section>
    </>
  )
}
export default Products
