import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Products from '../components/indexComponents/products'
import Customers from '../components/indexComponents/customers'
import PhilosophyAndMission from '../components/indexComponents/philosophyAndMission'
import Founders from '../components/indexComponents/founders'
import Contact from '../components/indexComponents/contact'
import Introduction from '../components/indexComponents/introduction'
import RictaLogo from '../images/1RictaLogo.png'

const IndexPage = () => {
    return (
        <div>
            <Layout>
                <Seo
                    title="Ricta Technologies"
                    description="A Software Boutique company with focus on developing new browsing solutions for
Forensic specialists and OSINT analysts."
                    image={RictaLogo}
                />
                <Introduction />
                <Products />
                <Customers />
                <PhilosophyAndMission />
                <Founders />
                <Contact />
            </Layout>
        </div>
    )
}
export default IndexPage
